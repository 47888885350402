import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LeftOutlined, MailOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { Button, Col, Form, Input, Row } from 'antd'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

import { RECOVER_PASSWORD } from 'graphql/mutations/login'

import 'antd/es/button/style/css'
import '../../styles/base.scss'
import './custom.scss'

const RecoverPassword = (props) => {
  const { setMessage, setPage } = props
  const [form] = Form.useForm()
  const { t } = useTranslation('login')
  const [email, setEmail] = useState('')

  const [recoverPassword] = useMutation(gql(RECOVER_PASSWORD), {
    variables: {
      input: {
        email,
      },
    },
    onCompleted: (data) => {
      if (isNil(get(data, ['recoverPassword', 'id']))) {
        form.setFields([
          {
            name: 'email',
            errors: [t('errors.unknown_email')],
          },
        ])
      } else {
        setMessage({
          text: `${t('recover_password_instructions')} ${email}`,
          type: 'success',
          isVisible: true,
        })
      }
    },
  })

  return (
    <>
      <div className="login-form-div">
        <div id="login-div">
          <Form onFinish={recoverPassword} className="login-form" form={form}>
            <span>{t('enter_email_for_password_recovery')}</span>
            <Form.Item
              name="email"
              hasFeedback
              className="recover-password-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              rules={[
                {
                  type: 'email',
                  message: t('errors.wrong_email_format'),
                },
              ]}
            >
              <Input prefix={<MailOutlined className="placeholder-icon" />} placeholder={t('placeholders.email')} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                {t('recover_password_button')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <br />
      <hr className="dotted-hr" />
      <Row className="footer-button">
        <Col span={24}>
          <Button onClick={() => setPage('login')}>
            <LeftOutlined />
            {t('back_to_login_button')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default RecoverPassword
