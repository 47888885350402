export const LOGIN = `
  mutation Login ($input: ResolveLoginInput!) {
    resolveLogin(input: $input) {
      redirectTo
    }
  }
`

export const LOGOUT = `
  mutation Logout {
    logOut(input: {}) {
      redirectUrl
    }
  }
`

export const RECOVER_PASSWORD = `
  mutation RecoverPassword ($input: RecoverPasswordInput!) {
    recoverPassword(input: $input) {
      id
    }
  }
`
