import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'

import { headers } from './headers'

const createApolloClient = (uri, jwtToken = null) =>
  new ApolloClient({
    link: createHttpLink({ uri, headers: jwtToken ? { ...headers, Authorization: `Bearer ${jwtToken}` } : headers }),
    cache: new InMemoryCache(),
  })

export { createApolloClient }
