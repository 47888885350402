import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LockOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { Button, Checkbox, Col, Form, Input, Row } from 'antd'

import { LOGIN } from 'graphql/mutations/login'

import 'antd/es/button/style/css'
import '../../styles/base.scss'
import './custom.scss'

const LoginForm = (props) => {
  const { handleResponse, setPage } = props
  const { t } = useTranslation('login')

  const [loginName, setLoginName] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(true)

  const [login] = useMutation(gql(LOGIN), {
    variables: {
      input: {
        login: loginName,
        password,
        remember,
      },
    },
    onCompleted: (data) => handleResponse(data.resolveLogin.redirectTo),
  })

  const openRecoverPassword = () => setPage('recoverPassword')

  return (
    <>
      <div className="login-form-div">
        <div id="login-div">
          <Form onFinish={login} className="login-form">
            <Form.Item hasFeedback name="login">
              <Input
                prefix={<UserOutlined className="placeholder-icon" />}
                placeholder={t('placeholders.login')}
                value={loginName}
                onChange={(e) => setLoginName(e.target.value)}
              />
            </Form.Item>
            <Form.Item hasFeedback name="password">
              <Input.Password
                prefix={<LockOutlined className="placeholder-icon" />}
                placeholder={t('placeholders.password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item initialValue={true} valuePropName="checked" name="remember">
              <Checkbox value={remember} onChange={(e) => setRemember(e.target.checked)}>
                {t('remember_password')}
              </Checkbox>
            </Form.Item>
            <Button
              type="link"
              role="button"
              tabIndex={0}
              className="login-form-forgot"
              onClick={openRecoverPassword}
              onKeyDown={openRecoverPassword}
            >
              {t('recover_password')}
            </Button>
            <Button type="primary" htmlType="submit" className="login-form-button">
              {t('log_in_button')}
            </Button>
          </Form>
        </div>
      </div>
      <br />
      <hr className="dotted-hr" />
      <Row className="footer-button">
        <Col span={24}>
          <Button href="/login">
            <LoginOutlined />
            {t('old_login_button')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default LoginForm
