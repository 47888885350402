import React from 'react'
import { Alert } from 'antd'
import isEmpty from 'lodash/isEmpty'

const ErrorMessage = (props) => {
  const { message } = props

  return (
    !isEmpty(message.text) && (
      <div className="form-message">
        <Alert message={message.text} type={message.type} showIcon />
      </div>
    )
  )
}

export default ErrorMessage
