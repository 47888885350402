import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import moment from 'moment-timezone'

import { getLocale } from 'utils/getLocale'

import cz from './cz.json'
import de from './de.json'
import en from './en.json'
import sk from './sk.json'

const resources = {
  en,
  sk,
  cz,
  de,
}

const bodyElement = document.getElementsByTagName('body')[0]
const locale = bodyElement.attributes['data-locale'].value
const timeZone = bodyElement.attributes['data-tz'].value

i18n.use(initReactI18next).init({
  resources,
  lng: locale,
  fallbackLng: 'en',
  returnObjects: true,
  interpolation: {
    escapeValue: false,
  },
})

moment.tz.setDefault(timeZone)
moment.locale(getLocale())

export default i18n
