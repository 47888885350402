import React from 'react'
import { ApolloProvider } from '@apollo/client'

import WrappedLoginPage from 'components/Login'
import { createApolloClient } from 'utils/apolloSettings'
import { withTranslations } from 'wrappers/withTranslations'

const LoginWrapper = (props) => (
  <ApolloProvider client={createApolloClient('/login/graphql')}>
    <WrappedLoginPage {...props} />
  </ApolloProvider>
)

export default withTranslations(LoginWrapper)
