const customAntLocaleCZ = {
  Table: {
    filterReset: 'Resetovat',
  },
}

const customAntLocaleSK = {
  Table: {
    filterReset: 'Resetovať',
  },
}

export { customAntLocaleCZ, customAntLocaleSK }
