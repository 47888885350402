import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import logoFingera from '../../../assets/images/logo_fingera.svg'

import ErrorMessage from './ErrorMessage'
import LoginForm from './LoginForm'
import RecoverPassword from './RecoverPassword'

import 'antd/es/button/style/css'
import '../../styles/base.scss'
import './custom.scss'
import('antd/dist/antd.css')

const emptyMessage = { text: '', type: '', isVisible: false }

const Login = () => {
  const { t } = useTranslation('login')

  const [page, setPage] = useState('login')
  const [message, setMessage] = useState(emptyMessage)

  const handleResponse = (redirectTo) => {
    if (redirectTo) {
      setMessage(emptyMessage)
      document.location.href = redirectTo
    } else {
      setMessage({
        text: t('errors.unsuccessful_login'),
        type: 'error',
        isVisible: true,
      })
    }
  }

  return (
    <div id="login-page-container">
      <div className="white-div">
        <div className="logo">
          <img src={logoFingera} alt="Fingera logo" />
        </div>
        <ErrorMessage message={message} />
        {page === 'login' && <LoginForm setPage={setPage} setMessage={setMessage} handleResponse={handleResponse} />}
        {page === 'recoverPassword' && <RecoverPassword setPage={setPage} setMessage={setMessage} />}
      </div>
    </div>
  )
}

export default Login
