import React from 'react'
import { ConfigProvider } from 'antd'
import localeCS from 'antd/es/locale/cs_CZ'
import localeDE from 'antd/es/locale/de_DE'
import localeEN from 'antd/es/locale/en_GB'
import localeSK from 'antd/es/locale/sk_SK'
import { customAntLocaleCZ, customAntLocaleSK } from 'translations/customAntLocale'

import { getLocale } from 'utils/getLocale'

import 'translations/i18n'

const setLocale = () => {
  const loc = getLocale()
  switch (loc) {
    case 'en':
      return localeEN
    case 'de':
      return localeDE
    case 'sk':
      return { ...localeSK, Table: { ...localeSK.Table, ...customAntLocaleSK.Table } }
    case 'cs':
      return { ...localeCS, Table: { ...localeCS.Table, ...customAntLocaleCZ.Table } }
  }
}

export const withTranslations = (WrappedComponent) => {
  const HOC = (props) => (
    <ConfigProvider locale={setLocale()}>
      <WrappedComponent {...props} />
    </ConfigProvider>
  )
  return HOC
}
